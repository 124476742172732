@import "./Constants/color.scss";
:root {
  --breadcrumbsList_h: 34px;
  --breadcrumbs-char_LRm: 11px;
  --breadcrumbsArrow_w: 6px;
  --breadcrumbs_bdrs: 5px;
}
* {
  box-sizing: border-box;
}
select:disabled.simple-control::-ms-expand {
  display: none;
}
.tooltip {
  width: auto !important;
  .tooltipKey {
    text-align: left;
  }
  .tooltipMain {
    display: flex;
    margin-top: -4px;
    margin-bottom: -8px;
    .tooltipValue {
      text-align: left;
      font-size: 16px;
    }
    .delta {
      display: none;
      .tooltipDelta {
        margin-top: 4px;
        margin-left: 8px;
        font-size: 11px;
        color: $green;
      }
      .red {
        color: $redText;
      }
    }
    .delta_display {
      display: flex;
    }
  }
}

.logo {
  width: 100px;
  cursor: pointer;
}
.green-text {
  color: $green;
}
.red-text {
  color: $redText;
}
.dashboard-select select {
  width: auto !important;
  display: "flex";
  margin-bottom: "0px";
}

.centered-graph {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .grid {
    stroke: rgba(183, 178, 178, 0.25882352941176473);
  }
}

.fa-info {
  margin-right: 8px;
  margin-left: 8px;
  font-size: 16px;
  color: #c5c7cb;
}

.info:hover .legends-box {
  visibility: visible;
}

.info {
  position: relative;
  display: none;
  cursor: pointer;
  .legends-box {
    visibility: hidden;
    position: absolute;
    top: 28px;
    right: -4px;
    background: #00000099;
    padding: 12px;
    border-radius: 5px;
    z-index: 1;
    p {
      color: $white !important;
      margin-bottom: 5px;
    }
    .triangle-up {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 11px solid #00000099;
      position: absolute;
      top: -11px;
      right: 9px;
      animation: fadein ease 0.2s;
    }
  }
}

#legend {
  display: flex;
  justify-content: center;
  width: 100%;
  .sub-legend {
    display: flex;
    align-items: baseline;
    font-size: 12px;
    margin-right: 15px;
    .square {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}

.bottom-legends .info:hover .bottom-legends-box {
  visibility: visible;
}

.bottom-legends {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .sub-legend {
    display: flex;
    align-items: baseline;
    font-size: 0.7vw;
    margin-right: 30px;
    color: $white;
    .square {
      width: 0.7vw;
      height: 0.7vw;
      margin-right: 5px;
    }
  }
  .info {
    position: relative;
    display: block;
    cursor: pointer;
    .bottom-legends-box {
      visibility: hidden;
      position: absolute;
      top: -225px;
      right: 25px;
      background: #00000099;
      padding: 12px;
      border-radius: 5px;
      z-index: 1;
      p {
        color: $white !important;
        margin-bottom: 5px;
      }
      .triangle-up {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 11px solid #00000099;
        border-bottom: 6px solid transparent;
        position: absolute;
        top: 228px;
        right: -10px;
        animation: fadein ease 0.2s;
      }
    }
  }
}
.cd-breadcrumb,
.cd-multi-steps {
  width: 100%;
  /* max-width: 768px;  */
  padding: 0.5em 1em;
  /* margin: 1em auto; */
  margin: 0.5em auto;
  background-color: $darkPrimary;
  border-radius: 0.25em;
}
/* Cabin select Options */
select.cabinselect {
  font-size: 0.9vw;
  border: solid 1px rgba(255, 255, 255, 0.23) !important;
  border-radius: 0;
  border: none;
  margin-left: 5px;
  color: white;
  background-color: $darkPrimary;
  -webkit-box-shadow: inset 0 1px 1px transparent;
  box-shadow: inset 0 1px 1px transparent;
}
select.dashboard-dropdown {
  border-bottom: none;
  font-size: 0.9vw;
  width: 100px;
}
.dashboard-dropdown[disabled] {
  background-color: $darkPrimary;
}
.cabin-selection {
  display: flex;
  border-right: 1px solid white;
  margin-right: 15px;
  .disable {
    pointer-events: none;
  }
  .MuiSelect-select.MuiSelect-select:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
  .MuiInputLabel-formControl {
    z-index: 11;
    color: white;
    font-size: 0.9vw;
    top: -10px;
    left: 16px;
    margin-top: -5px;
  }
  .MuiInputLabel-shrink {
    transform: translateY(10px);
    display: none;
  }
  .MuiFormLabel-root.Mui-focused {
    display: none;
  }
  label + .MuiInput-formControl {
    margin-top: 0px;
    margin-right: 10px;
    border: solid 1px rgba(255, 255, 255, 0.23) !important;
    border-radius: 0;
    border: none;
    margin-left: 5px;
    color: white;
    background-color: #2e303f;
    box-shadow: inset 0 1px 1px transparent;
  }
  .MuiSelect-selectMenu {
    height: 15px;
    font-size: 14px;
    padding-top: 11px;
    padding-left: 5px;
  }
  .MuiSelect-icon {
    color: $white;
    top: 6px;
  }
  .MuiInput-underline:before {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
}

.MuiMenu-paper {
  .MuiListItemText-root {
    .MuiTypography-root {
      font-size: 0.9vw !important;
    }
  }
}

.dds-chart-btn {
  width: 90.5% !important;
  padding: 4%;
  margin-top: 5px;
  margin-bottom: 3px;
  margin-left: 12px !important;
}

.search {
  background: $darkOrange;
  width: 95px;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 10px;
}
.search:hover,
.search:focus {
  background: $darkOrange;
  color: $white;
}

.fa-search {
  font-size: 25px;
  cursor: pointer;
  color: #f54641;
  border-right: 1px solid white;
  padding-right: 10px;
  padding-top: 4px;
}

select.pos-route-dropdown {
  border-bottom: none;
  background-color: $darkPrimary;
  font-size: 14px;
  width: 90px;
  margin-right: 10px;
  cursor: pointer;
}
select.currency-dropdown {
  border-bottom: none;
  background-color: $darkPrimary;
  font-size: 14px;
  width: auto;
  margin-right: 10px;
  cursor: pointer;
}
.currency-dropdown[disabled] {
  background-color: $darkPrimary;
  color: white;
}
input[type="text"][disabled] {
  color: red;
}
// .download:focus {
//   background: $darkOrange;
// }
// .download {
//   background: $darkOrange;
//   width: auto;
//   outline: none;
//   border: none;
//   margin-left: 10px;
//   font-size: 13px;
//   float: right;
//   color: $white;
//   font-size: 12px !important;
//   text-transform: uppercase;
//   i {
//     margin-left: 5px;
//   }
// }
.download {
  margin-left: 5px;
  i {
    color: $white;
    margin-left: 5px;
  }
}
.download:hover,
.download:focus {
  color: $white;
}
.breadcrumbs {
  display: inline-block;
  font-size: 0;
}
.breadcrumbsList {
  display: inline-block;
  color: #333;
  font: 16px / var(--breadcrumbsList_h) "Noto Sans JP", sans-serif;
  text-decoration: none;
  height: var(--breadcrumbsList_h);
  padding: 0 var(--breadcrumbs-char_LRm) 0
    calc(var(--breadcrumbs-char_LRm) + var(--breadcrumbsArrow_w));
  position: relative;
}
.breadcrumbsList:first-of-type {
  padding-left: var(--breadcrumbs-char_LRm);
  border-top-left-radius: var(--breadcrumbs_bdrs);
  border-bottom-left-radius: var(--breadcrumbs_bdrs);
}
.breadcrumbsList:last-of-type {
  border-top-right-radius: var(--breadcrumbs_bdrs);
  border-bottom-right-radius: var(--breadcrumbs_bdrs);
}
.breadcrumbsList::after {
  content: "";
  display: block;
  border-top: calc(var(--breadcrumbsList_h) / 2) solid transparent;
  border-bottom: calc(var(--breadcrumbsList_h) / 2) solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
}

.breadcrumbsList {
  background: #ccd6da;
}
.breadcrumbsList::after {
  border-left: var(--breadcrumbsArrow_w) solid #bcbebf;
}
/* .breadcrumbsList:nth-of-type(1) {background: #b3e5fc;} */
/* .breadcrumbsList:nth-of-type(2) {background: #81d4fa;} */
/* .breadcrumbsList:nth-of-type(3) {background: #4fc3f7;} */
/* .breadcrumbsList:nth-of-type(1)::after {border-left: var(--breadcrumbsArrow_w) solid #b3e5fc;} */
/* .breadcrumbsList:nth-of-type(2)::after {border-left: var(--breadcrumbsArrow_w) solid #81d4fa;} */
.breadcrumbsList:hover {
  transform: scale(1.1);
  z-index: 2;
}

a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}
.nav-tabs > li.active > a {
  background-color: $darkPrimary;
}
#posTableTab .nav-tabs {
  border-bottom: none;
  position: relative;
}
#posTableTab .nav-tabs li {
  margin-right: 15px;
}
#posTableTab .nav-tabs li a {
  padding: 8px 8px;
  font-size: 13px !important;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
  font-weight: 500;
  letter-spacing: 0.0055em;
  color: $orange;
  border-radius: 0;
  text-transform: uppercase;
  margin-right: 0;
  border: none;
  position: relative;
  transition: all 0.5s ease 0s;
}
#posTableTab .nav-tabs li a:hover {
  background: $header;
}
#posTableTab .nav-tabs li a:before {
  content: "";
  width: 100%;
  height: 1px;
  background: $orange;
  position: absolute;
  bottom: 5px;
  left: 0;
  transform: scale(0);
  transition: all 700ms ease 0s;
}
#posTableTab .nav-tabs li a:after {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $orange;
  margin: 0 auto;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  transform: scale(0);
  transition: all 700ms ease 0s;
}
#posTableTab .nav-tabs li.active a,
#posTableTab .nav-tabs li.active a:focus,
#posTableTab .nav-tabs li.active a:hover {
  border: none;
  color: $orange;
  background-color: $darkPrimary;
}
#posTableTab .nav-tabs li a:hover:before,
#posTableTab .nav-tabs li.active a:before,
#posTableTab .nav-tabs li a:hover:after,
#posTableTab .nav-tabs li.active a:after {
  transform: scale(1);
}
#posTableTab .tab-content {
  padding: 5px 0px 0px 0px;
  font-size: 14px;
  color: $darkPrimary;
  /* line-height: 26px; */
}
#posTableTab .tab-content h3 {
  font-size: 24px;
  margin-top: 0;
}

.spinnertable {
  margin: 0px auto;
  margin-top: 5%;
  width: 100%;
  height: 100px;
  text-align: center;
  font-size: 10px;
}

.spinnertable > div {
  background-color: $blue;
  height: 100%;
  width: 7px;
  margin-left: 2px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinnertable .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinnertable .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinnertable .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinnertable .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.spinnertable .rect6 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.7s;
}
.loader {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: -1040;
}
.App-loader {
  width: 50px;
}
.loader-visible {
  opacity: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex !important;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
}

.swal2-title {
  color: #595959 !important;
}

h2 {
  color: $lightGreen !important;
  font-size: 1vw !important;
}

h4 {
  font-size: 14px !important;
  color: $white;
}

a {
  font-size: 14px !important;
}

.top-section {
  background-color: $darkSencondary !important;
}

.top {
  background-color: $darkPrimary !important;
  margin-top: 10px;
}

.navdesign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $darkPrimary;
  margin-top: 10px;
}

.navbar-right li a:hover {
  background: transparent !important;
}

.modal-content {
  background-color: $white !important;
  color: $primaryColor;
}
.modal-header .modal-title {
  color: $primaryColor;
}
.modal-header .close {
  color: $primaryColor;
  opacity: 1;
}

.copyright-message {
  width: fit-content;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 20px 0 auto;
  color: orange;
  font-size: 10.5px;
  background-color: black;
  transition: all 0.1s ease;

  a {
    color: orange;
    font-size: 10.5px !important;
  }
}

.copyright-message.grow {
  font-size: 8px;

  &:hover {
    font-size: 12px;
    a {
      font-size: 12px !important;
    }
  }
  a {
    font-size: 8px !important;
  }
}

@media only screen and (max-width: 479px) {
  #posTableTab .nav-tabs li {
    width: 100%;
  }
  #posTableTab .nav-tabs li a {
    text-align: center;
  }
}

@media (max-height: 700px) {
  .info {
    display: block;
  }
  .bottom-legends {
    display: none;
  }
  h2 {
    font-size: 13px !important;
  }
}
